import { memo } from "react";
import { useSelector } from "react-redux";

const Teamsection = ({ teamName, teamScore }) => {
  const { sportLiveEventData } = useSelector((state) => state.cricket);
  return (
    <>
      <div className="team-content">
        <div className="team_name">{teamName.name}</div>
        {teamName.type === "homeRunner" ? (
          <div className="curr_inn">
            <span className="run">
              {sportLiveEventData.homeRunner.displayScore}
            </span>
            {/* <span className="over">(0.0 OVERS)</span> */}
            <br />
            <span className="run-rate">
              CRR : {sportLiveEventData.homeRunner.firstInningScore.cr} | RRR:
              0.0
            </span>
          </div>
        ) : (
          <div className="curr_inn">
            <span className="run">
              {sportLiveEventData.awayRunner.displayScore}
            </span>
            {/* <span className="over">(0.0 OVERS)</span> */}
            <br />
            <span className="run-rate">
              CRR : {sportLiveEventData.awayRunner.firstInningScore.cr} | RRR:
              0.0
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(
  Teamsection,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
);
