const NotAvailable = ({ message }) => {
  return (
    <>
      <div className="scorecard-main-message">
        <div className="scorecard-box">
          <div className="scorecard-inprogress">
            {(message !== "" && <p>{message}</p>) || (
              <p>Live Score is not available for this event.</p>
            )}
          </div>
        </div>
      </div>
      ;
    </>
  );
};
export default NotAvailable;
