import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Scorecard from "./views/pages/miniScorecard/index";
import Newscore from "./views/pages/miniScorecard/test/index";

const App = () => {
  return (
    <div className="col-12">
      <Router>
        <Suspense>
          <Routes>
            <Route path="/:token/:matchId/:sportId" element={<Scorecard />} />;
            <Route
              path="/test/:token/:matchId/:sportId"
              element={<Newscore />}
            />
            ;
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
