import moment from "moment";
import { memo, useState } from "react";
import { useSelector } from "react-redux";

const Eventstatussection = ({ feedStatus, eventData }) => {
  const { sportEventData, sportLiveEventData } = useSelector(
    (state) => state.cricket
  );
  const [timeRemainingToStartMatch, setTimeRemainingToStartMatch] =
    useState("");

  if (
    sportLiveEventData !== null &&
    sportLiveEventData.eventMessage.includes(
      "{{MATCH_START_HOURS}} {{MATCH_START_MINS}}"
    )
  ) {
    if (
      sportEventData.eventDateTime >
      moment(new Date(Date.now())).local().format("YYYY-MM-DD HH:mm:ss")
    ) {
      setInterval(MATCHSTARTCOUNTDOWNTIMER, 1000);
    } else {
      setTimeRemainingToStartMatch("");
    }

    function MATCHSTARTCOUNTDOWNTIMER() {
      const datetime = sportEventData.eventDateTime;
      const statusInfo = sportLiveEventData.eventMessage;

      const currentDateTime = moment(new Date(Date.now()))
        .local()
        .format("YYYY-MM-DD HH:mm:ss");

      const eventDateTime = moment(datetime)
        .local()
        .format("YYYY-MM-DD HH:mm:ss");

      const rawSeconds = moment(eventDateTime).diff(
        moment(currentDateTime),
        "seconds"
      );
      const seconds = Math.floor(rawSeconds);
      const MATCH_START_DAY = Math.floor(seconds / 86400);
      const MATCH_START_HOURS = Math.floor(seconds / 3600);
      const MATCH_START_MINS = Math.floor((seconds % 3600) / 60);
      const MATCH_START_SECS = seconds % 60;

      const newStatus = statusInfo.replace(
        "{{MATCH_START_HOURS}} {{MATCH_START_MINS}}",
        `${MATCH_START_DAY}D ${MATCH_START_HOURS}H ${MATCH_START_MINS}M ${MATCH_START_SECS}S`
      );
      setTimeRemainingToStartMatch(newStatus);
    }
  }

  return (
    <>
      {feedStatus !== null ? (
        <div className="match_status">
          {timeRemainingToStartMatch !== "" ? (
            <span className="commantry">{timeRemainingToStartMatch}</span>
          ) : feedStatus.isEventLive === 1 &&
            feedStatus.eventStatus !== "In-progress" ? (
            <span className="commantry">{feedStatus.eventStatus}</span>
          ) : feedStatus.isEventLive === 1 &&
            feedStatus.eventStatus === "In-progress" ? (
            <span className="commantry">{feedStatus.eventStatus}</span>
          ) : feedStatus.isEventLive === 0 &&
            feedStatus.eventStatus !== "In-progress" ? (
            <span className="commantry">{feedStatus.eventStatus}</span>
          ) : null}

          {feedStatus.eventMessage !== "" && (
            <span className="target">{feedStatus.eventMessage}</span>
          )}

          <div className="day">
            {eventData.eventDateTime >
            moment(new Date(Date.now()))
              .local()
              .format("YYYY-MM-DD HH:mm:ss") ? (
              <div className="">
                <b>
                  <span>V</span> <span>S</span>
                </b>
              </div>
            ) : null}

            <div className="score-over">
              <ul>
                {feedStatus.recentBalls !== null &&
                  Object.keys(feedStatus.recentBalls).length > 0 &&
                  feedStatus?.recentBalls.map((item) => {
                    if (
                      item?.runs === "0" ||
                      item?.runs === "1" ||
                      item?.runs === "2" ||
                      item?.runs === "3"
                    ) {
                      return <li className="six-balls">{item?.runs}</li>;
                    } else if (item.runs === "4" || item.runs === "6") {
                      return <li className="six-balls b-color">{item.runs}</li>;
                    }
                  })}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="match_status">
          {timeRemainingToStartMatch !== "" ? (
            <span className="commantry">{timeRemainingToStartMatch}</span>
          ) : (
            <span className="commantry">
              {moment(eventData.eventDateTime).format("ddd DD MMM, h:mm A")}
            </span>
          )}

          <span className="target"></span>
          <div className="day">
            {eventData.eventDateTime >
            moment(new Date(Date.now()))
              .local()
              .format("YYYY-MM-DD HH:mm:ss") ? (
              <div className="">
                <b>
                  <span>V</span> <span>S</span>
                </b>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(
  Eventstatussection,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
);
