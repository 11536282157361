import { SPORT_EVENT_DATA, LIVE_SPORT_EVENT_DATA } from "./types";

export const setSportEventData = (data) => (dispatch) => {
  dispatch({
    type: SPORT_EVENT_DATA,
    payload: data,
  });
};

export const setLiveEventData = (data) => (dispatch) => {
  dispatch({
    type: LIVE_SPORT_EVENT_DATA,
    payload: data,
  });
};
