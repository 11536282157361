import { SPORT_EVENT_DATA, LIVE_SPORT_EVENT_DATA } from "./types";

const initialState = {
  sportEventData: null,
  sportLiveEventData: null,
};

export default (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case SPORT_EVENT_DATA:
      return {
        ...state,
        sportEventData: payload,
      };
    case LIVE_SPORT_EVENT_DATA:
      return {
        ...state,
        sportLiveEventData: payload,
      };
    default:
      return state;
  }
};
