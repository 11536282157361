import React, { useCallback, useEffect, useState } from "react";
import { json, useParams } from "react-router-dom";
import socket from "../../../../utils/socketUtil";
import liveFeed from "../../../../services/httpRequest";
import Cricket from "./partials/cricket";
import Soccer from "./partials/soccer";
import Tennis from "./partials/tennis";
import NotAvailable from "./liveFeedNotAvailable";
import { useDispatch, useSelector } from "react-redux";
import {
  setLiveEventData,
  setSportEventData,
} from "../../../../redux/cricket/actions";

const Newscore = () => {
  const { token, matchId, sportId } = useParams();

  const dispatch = useDispatch();
  const { sportEventData, sportLiveEventData } = useSelector(
    (state) => state.cricket
  );

  const [cricketEventData, setCricketEventData] = useState({});
  const [footballEventData, setFootballEventData] = useState({});
  const [tennisEventData, setTennisEventData] = useState({});

  const [liveCricketDataFeed, setliveCricketDataFeed] = useState({});
  const [liveFootballDataFeed, setliveFootballDataFeed] = useState({});
  const [liveTennisDataFeed, setliveTennisDataFeed] = useState({});

  const [apiResponseMessage, setApiResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getApiMatchIdByBetfairMatchId = useCallback(
    async (token, matchId, sportId) => {
      const response = await liveFeed.getTestApiMatchIdByBetfairMatchId(
        token,
        matchId,
        sportId,
        window.location.ancestorOrigins[0]
      );

      if (response.data) {
        localStorage.setItem(
          `eventData_${matchId}_${sportId}`,
          JSON.stringify(response.data.data)
        );

        if (sportId === "4") {
          setCricketEventData(response.data.data);
          setliveCricketDataFeed(response.data.data.feedData);
          dispatch(setLiveEventData(response.data.data.feedData));
          dispatch(setSportEventData(response.data.data));
        } else if (sportId === "1") {
          setFootballEventData(response.data.data);
          setliveFootballDataFeed(response.data.data.feedData);
        } else if (sportId === "2") {
          setTennisEventData(response.data.data);
          setliveTennisDataFeed(response.data.data.feedData);
        }
      } else if (response.data.error) {
        setApiResponseMessage(response.data.error?.message);
      }
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    if (token && matchId && sportId) {
      getApiMatchIdByBetfairMatchId(token, matchId, sportId);
      localStorage.setItem("token", token);
      const eventKey = `test_${matchId}_${sportId}`;

      socket.on(eventKey, (response) => {
        if (response) {
          if (sportId === "4") {
            setliveCricketDataFeed(response);
            dispatch(setLiveEventData(response));
          } else if (sportId === "1") {
            setliveFootballDataFeed(response);
          } else if (sportId === "2") {
            setliveTennisDataFeed(response);
          }
        }
      });
    }
  }, [token, matchId, sportId, socket, getApiMatchIdByBetfairMatchId]);

  return (
    <>
      {isLoading ? (
        <div className="scorecard-pre-loader">
          <div className="loader"></div>
        </div>
      ) : Object.keys(cricketEventData).length > 0 ? (
        <Cricket
          eventDetials={cricketEventData}
          feedData={liveCricketDataFeed}
        />
      ) : Object.keys(footballEventData).length > 0 ? (
        <Soccer
          eventDetials={footballEventData}
          feedData={liveFootballDataFeed}
        />
      ) : Object.keys(tennisEventData).length > 0 ? (
        <Tennis eventDetials={tennisEventData} feedData={liveTennisDataFeed} />
      ) : (
        <NotAvailable message={apiResponseMessage} />
      )}
    </>
  );
};

export default Newscore;
