import axiosService from "../utils/axiosService";

const getApiMatchIdByBetfairMatchId = async (
  token,
  matchId,
  sportId,
  hostname
) => {
  try {
    const response = await axiosService.get(
      `feedapi/getTodaysEventData/${token}/${matchId}/${sportId}`,
      {
        headers: {
          ancestorOrigins: hostname,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getLiveFeedDataByAPIEventKey = async (token, matchId, sportId) => {
  try {
    const response = await axiosService.get(
      `/feedapi/${token}/${matchId}/${sportId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getTestApiMatchIdByBetfairMatchId = async (
  token,
  matchId,
  sportId,
  hostname
) => {
  try {
    const response = await axiosService.get(`/mini/events/${matchId}`, {
      headers: {
        ancestorOrigins: hostname,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const liveFeed = {
  getApiMatchIdByBetfairMatchId,
  getTestApiMatchIdByBetfairMatchId,
  getLiveFeedDataByAPIEventKey,
};

export default liveFeed;
