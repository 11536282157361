import moment from "moment";
import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircle } from "@fortawesome/free-solid-svg-icons";

const Soccer = ({ eventDetials, feedData }) => {
  const [eventData, setEventData] = useState(eventDetials);
  const [liveDataFeed, setliveDataFeed] = useState(feedData);

  useEffect(() => {
    setliveDataFeed(feedData);
    setEventData(eventDetials);
  }, [feedData, eventDetials]);

  console.log(eventData);
  console.log(liveDataFeed);

  return (
    <>
      <div className="scorecard-main-container scorecardSoccer">
        <div className="scorecard-row ">
          <div className="team-content">
            {(liveDataFeed?.feedData?.matchSummary?.eventResult === "-" && (
              <p className="score"> - </p>
            )) ||
              (liveDataFeed?.feedData?.matchSummary?.eventResult !== "-" && (
                <p className="score">
                  {
                    liveDataFeed?.feedData?.matchSummary?.eventResult.split(
                      " - "
                    )[0]
                  }
                </p>
              ))}

            <div className="team_name">{eventData.eventTeamA}</div>
          </div>

          <div className="match_status">
            {(liveDataFeed?.feedData?.matchSummary?.status === "Finished" && (
              <span className="commantry">
                {liveDataFeed?.feedData?.matchSummary?.status}
              </span>
            )) ||
              (liveDataFeed?.feedData?.matchSummary?.status === "" && (
                <span className="commantry">
                  {moment(liveDataFeed?.feedData?.eventInfo?.dateTime).format(
                    "ddd DD MMM, h:mm A"
                  )}
                </span>
              ))}

            <span className="day">
              <div className="set-servie_score">
                {(liveDataFeed?.feedData?.matchSummary?.status === "" && (
                  <div className="">
                    <b>
                      <span>V</span> <span>S</span>
                    </b>
                  </div>
                )) ||
                  ((liveDataFeed?.feedData?.matchSummary?.status < "45" ||
                    liveDataFeed?.feedData?.matchSummary?.status === "45+") && (
                    <span className="event-half-time">
                      1ST HALF <br />
                      {liveDataFeed?.feedData?.matchSummary?.status}
                    </span>
                  )) ||
                  (liveDataFeed?.feedData?.matchSummary?.status > "45" &&
                    liveDataFeed?.feedData?.matchSummary?.status !==
                      "Half Time" && (
                      <span className="event-half-time">
                        2ND HALF
                        <br />
                        {liveDataFeed?.feedData?.matchSummary?.status}
                      </span>
                    )) ||
                  (liveDataFeed?.feedData?.matchSummary?.status ===
                    "Half Time" && (
                    <span className="event-half-time">Half Time</span>
                  ))}

                {(liveDataFeed?.feedData?.matchSummary?.eventResult.split(
                  " - "
                )[0] >
                  liveDataFeed?.feedData?.matchSummary?.eventResult.split(
                    " - "
                  )[1] &&
                  liveDataFeed?.feedData?.matchSummary?.status ===
                    "Finished" && (
                    <span className="target">
                      {liveDataFeed?.feedData?.matchSummary?.homeTeam?.name} won
                      the match by{" "}
                      {liveDataFeed?.feedData?.matchSummary?.eventResult}
                    </span>
                  )) ||
                  (liveDataFeed?.feedData?.matchSummary?.eventResult.split(
                    " - "
                  )[0] <
                    liveDataFeed?.feedData?.matchSummary?.eventResult.split(
                      " - "
                    )[1] &&
                    liveDataFeed?.feedData?.matchSummary?.status ===
                      "Finished" && (
                      <span className="target">
                        {liveDataFeed?.feedData?.matchSummary?.awayTeam?.name}{" "}
                        won the match by{" "}
                        {liveDataFeed?.feedData?.matchSummary?.eventResult}
                      </span>
                    )) ||
                  (liveDataFeed?.feedData?.matchSummary?.eventResult.split(
                    " - "
                  )[0] ===
                    liveDataFeed?.feedData?.matchSummary?.eventResult.split(
                      " - "
                    )[1] &&
                    liveDataFeed?.feedData?.matchSummary?.status ===
                      "Finished" && (
                      <span className="target">Match Tied/ Draw</span>
                    ))}
              </div>
            </span>
          </div>

          <div className="team-content">
            {(liveDataFeed?.feedData?.matchSummary?.eventResult === "-" && (
              <p className="score"> - </p>
            )) ||
              (liveDataFeed?.feedData?.matchSummary?.eventResult !== "-" && (
                <p className="score">
                  {
                    liveDataFeed?.feedData?.matchSummary?.eventResult.split(
                      " - "
                    )[1]
                  }
                </p>
              ))}
            <div className="team_name">{eventData.eventTeamB}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Soccer;
