import axios from "axios";

export const axiosService = axios.create({
  baseURL: process.env.REACT_APP_API_END_POINT,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

axiosService.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosService;
