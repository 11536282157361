import moment from "moment";
import React, { useEffect, useState } from "react";

const Cricket = ({ eventDetials, feedData }) => {
  const [eventData, setEventData] = useState(eventDetials);
  const [liveDataFeed, setliveDataFeed] = useState(feedData);

  const [timeRemainingToStartMatch, setTimeRemainingToStartMatch] =
    useState("");

  useEffect(() => {
    setliveDataFeed(feedData);
    setEventData(eventDetials);
  }, [eventDetials, feedData]);

  if (
    liveDataFeed?.feedData?.matchSummary?.status_info.includes(
      "{{MATCH_START_HOURS}} {{MATCH_START_MINS}}"
    ) &&
    liveDataFeed.feedData.eventInfo?.dateTime <
      moment(new Date(Date.now())).local().format("YYYY-MM-DD HH:mm:ss")
  ) {
    setInterval(MATCHSTARTCOUNTDOWNTIMER, 1000);

    function MATCHSTARTCOUNTDOWNTIMER() {
      const datetime = liveDataFeed.feedData.eventInfo?.dateTime;
      const statusInfo = liveDataFeed.feedData.matchSummary?.status_info;

      const currentDateTime = moment(new Date(Date.now()))
        .local()
        .format("YYYY-MM-DD HH:mm:ss");

      const eventDateTime = moment(datetime)
        .local()
        .format("YYYY-MM-DD HH:mm:ss");

      const rawSeconds = moment(eventDateTime).diff(
        moment(currentDateTime),
        "seconds"
      );
      const seconds = Math.floor(rawSeconds);
      const MATCH_START_DAY = Math.floor(seconds / 86400);
      const MATCH_START_HOURS = Math.floor(seconds / 3600);
      const MATCH_START_MINS = Math.floor((seconds % 3600) / 60);
      const MATCH_START_SECS = seconds % 60;

      const newStatus = statusInfo.replace(
        "{{MATCH_START_HOURS}} {{MATCH_START_MINS}}",
        `${MATCH_START_DAY}D ${MATCH_START_HOURS}H ${MATCH_START_MINS}M ${MATCH_START_SECS}S`
      );

      setTimeRemainingToStartMatch(newStatus);
    }
  }

  // const calculateCurrentRunRate = (data) => {
  //   const runRate = parseFloat(
  //     data?.feedData?.matchSummary?.homeTeam?.scores.split("/")[0] /
  //       data?.feedData?.matchSummary?.homeTeam?.overs.split(" ")[0]
  //   ).toFixed(2);
  //   if (runRate === "NaN") {
  //     return "0.0";
  //   } else {
  //     return runRate;
  //   }
  // };

  // const calculateRequiredRunRate = (data) => {
  //   const status = data?.feedData?.matchSummary?.status_info.includes("runs");
  //   if (status) {
  //     const runs = data?.feedData?.matchSummary?.status_info.split(" ")[2];
  //     const balls = data?.feedData?.matchSummary?.status_info.split(" ")[5];

  //     return parseFloat(runs / (balls / 6)).toFixed(2) === "NaN"
  //       ? "0.00"
  //       : parseFloat(runs / (balls / 6)).toFixed(2);
  //   } else {
  //     return "0.00";
  //   }
  // };

  const homeTeam =
    eventData.eventTeamA === liveDataFeed?.feedData?.matchSummary?.awayTeam.name
      ? liveDataFeed?.feedData?.matchSummary?.awayTeam
      : liveDataFeed?.feedData?.matchSummary?.homeTeam;

  const awayTeam =
    eventData.eventTeamB === liveDataFeed?.feedData?.matchSummary?.homeTeam.name
      ? liveDataFeed?.feedData?.matchSummary?.homeTeam
      : liveDataFeed?.feedData?.matchSummary?.awayTeam;

  return (
    <>
      <div className="scorecard-main-container scorecardCricket">
        <div className="scorecard-row">
          <div className="team-content">
            <div className="team_name">{eventData.eventShortTeamA} </div>
            <div className="curr_inn">
              {!homeTeam?.scores &&
              eventData.feedData.cricket.homeRunner.firstInningScore.runs !==
                0 ? (
                <span className="run">
                  {`${eventData.feedData.cricket.homeRunner.firstInningScore.runs}/${eventData.feedData.cricket.homeRunner.firstInningScore.wickets}`}
                </span>
              ) : !homeTeam?.scores &&
                eventData.feedData.cricket.homeRunner.firstInningScore.runs !==
                  0 &&
                eventData.feedData.cricket.homeRunner.secondInningScore.runs !==
                  0 ? (
                <span className="run">
                  {" "}
                  {`${eventData.feedData.cricket.homeRunner.firstInningScore.runs}/${eventData.feedData.cricket.homeRunner.firstInningScore.wickets}`}{" "}
                  &{" "}
                  {`${eventData.feedData.cricket.homeRunner.secondInningScore.runs}/${eventData.feedData.cricket.homeRunner.secondInningScore.wickets}`}
                </span>
              ) : (
                <span className="run">{homeTeam?.scores}</span>
              )}

              {homeTeam?.scores.includes("&") ||
                (eventData.feedData?.cricket?.homeRunner.secondInningScore
                  .runs !== 0 && <br />)}

              {!homeTeam?.overs &&
              eventData.feedData.cricket.homeRunner.firstInningScore.overs !==
                0 &&
              eventData.feedData.cricket.homeRunner.secondInningScore.overs ===
                0 ? (
                <span className="over">
                  (
                  {eventData.feedData.cricket.homeRunner.firstInningScore.overs}
                  )
                </span>
              ) : !homeTeam?.overs &&
                eventData.feedData.cricket.homeRunner.firstInningScore.overs !==
                  0 &&
                eventData.feedData.cricket.homeRunner.secondInningScore
                  .overs !== 0 ? (
                <span className="over">
                  (
                  {eventData.feedData.cricket.homeRunner.firstInningScore.overs}{" "}
                  &{" "}
                  {
                    eventData.feedData.cricket.homeRunner.secondInningScore
                      .overs
                  }
                  )
                </span>
              ) : (
                <span className="over">({homeTeam?.overs})</span>
              )}

              <br />
              <span className="run-rate">
                CRR : {Number(liveDataFeed.hmTeamCr)} | RRR:{" "}
                {Number(liveDataFeed.hmTeamRR)}
              </span>
            </div>
          </div>

          <div className="match_status">
            {timeRemainingToStartMatch !== "" && (
              <span className="commantry">{timeRemainingToStartMatch}</span>
            )}

            {timeRemainingToStartMatch === "" &&
              liveDataFeed?.feedData?.matchSummary?.status !==
                "In Progress" && (
                <span className="commantry">
                  {liveDataFeed?.feedData?.matchSummary?.status}
                </span>
              )}

            {liveDataFeed?.feedData?.matchSummary?.status === "" &&
              liveDataFeed?.feedData?.matchSummary?.status_info ===
                "Match yet to begin" && (
                <span className="commantry">
                  {moment(liveDataFeed?.feedData?.eventInfo?.dateTime).format(
                    "ddd DD MMM, h:mm A"
                  )}
                </span>
              )}

            {eventData.feedData.cricket &&
              eventData.feedData.cricket.eventStatus !== "" &&
              eventData.feedData.cricket.eventStatus !== "Finished" &&
              eventData.feedData.cricket.isEventLive === 0 && (
                <span className="target">
                  {eventData.feedData.cricket.eventStatus}
                </span>
              )}

            {timeRemainingToStartMatch === "" &&
              liveDataFeed?.feedData?.matchSummary?.status ===
                "In Progress" && (
                <span className="commantry">
                  {liveDataFeed?.feedData?.matchSummary?.status}
                </span>
              )}

            {timeRemainingToStartMatch === "" &&
              liveDataFeed?.feedData?.matchSummary?.status === "In Progress" &&
              liveDataFeed?.feedData?.matchSummary?.status_info.includes(
                "runs"
              ) && (
                <span className="target">
                  {liveDataFeed?.feedData?.matchSummary?.status_info}
                </span>
              )}

            <span className="day">
              {liveDataFeed?.feedData?.matchSummary?.status === "" && (
                <div className="">
                  <b>
                    <span>V</span> <span>S</span>
                  </b>
                </div>
              )}
              <div className="score-over">
                <ul>
                  {liveDataFeed?.feedData?.matchSummary?.recentBalls.map(
                    (item) => {
                      if (
                        item?.runs === "0" ||
                        item?.runs === "1" ||
                        item?.runs === "2" ||
                        item?.runs === "3"
                      ) {
                        return <li className="six-balls">{item?.runs}</li>;
                      } else if (item?.runs === "4" || item?.runs === "6") {
                        return (
                          <li className="six-balls b-color">{item?.runs}</li>
                        );
                      }
                    }
                  )}
                </ul>
              </div>
            </span>
          </div>

          <div className="team-content">
            <div className="team_name">{eventData.eventShortTeamB} </div>
            <div className="curr_inn">
              {!awayTeam?.scores &&
              eventData.feedData.cricket.awayRunner.firstInningScore.runs !==
                0 &&
              eventData.feedData.cricket.awayRunner.secondInningScore.runs ===
                0 ? (
                <span className="run">
                  {`${eventData.feedData.cricket.awayRunner.firstInningScore.runs}/${eventData.feedData.cricket.awayRunner.firstInningScore.wickets}`}
                </span>
              ) : !awayTeam?.scores &&
                eventData.feedData.cricket.awayRunner.firstInningScore.runs !==
                  0 &&
                eventData.feedData.cricket.awayRunner.secondInningScore.runs !==
                  0 ? (
                <span className="run">
                  {" "}
                  {`${eventData.feedData.cricket.awayRunner.firstInningScore.runs}/${eventData.feedData.cricket.awayRunner.firstInningScore.wickets}`}{" "}
                  &{" "}
                  {`${eventData.feedData.cricket.awayRunner.secondInningScore.runs}/${eventData.feedData.cricket.awayRunner.secondInningScore.wickets}`}
                </span>
              ) : (
                <span className="run">{awayTeam?.scores}</span>
              )}

              {awayTeam?.scores.includes("&") ||
                (eventData.feedData?.cricket?.awayRunner.secondInningScore
                  .runs !== 0 && <br />)}
              {!awayTeam?.overs &&
              eventData.feedData.cricket.awayRunner.firstInningScore.overs !==
                0 &&
              eventData.feedData.cricket.awayRunner.secondInningScore.overs ===
                0 ? (
                <span className="over">
                  (
                  {eventData.feedData.cricket.awayRunner.firstInningScore.overs}
                  )
                </span>
              ) : !awayTeam?.overs &&
                eventData.feedData.cricket.awayRunner.firstInningScore.overs !==
                  0 &&
                eventData.feedData.cricket.awayRunner.secondInningScore
                  .overs !== 0 ? (
                <span className="over">
                  (
                  {eventData.feedData.cricket.awayRunner.firstInningScore.overs}{" "}
                  &{" "}
                  {
                    eventData.feedData.cricket.awayRunner.secondInningScore
                      .overs
                  }
                  )
                </span>
              ) : (
                <span className="over">({awayTeam?.overs})</span>
              )}
              <br />
              <span className="run-rate">
                CRR : {Number(liveDataFeed.hmTeamCr)} | RRR:{" "}
                {Number(liveDataFeed.hmTeamRR)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cricket;
