import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { socketFunc } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";

function SocketProvider({ children }) {
  // const token = useSelector((state) => state.auth?.token);
  // const betbyDetails = useSelector((state) => state.app?.betbyDetails);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const socket = socketFunc(token);

  //   if (token) {
  //     socket.on("connect_error", async (error) => {
  //       if (error?.data?.type === "SessionError") {
  //         Toaster(APP_CONST.TOAST_TYPE.ERROR, error?.data?.message);
  //         // dispatch(logoutUser());
  //         window.location.href = "/";
  //         return;
  //       }
  //       // revert to classic upgrade
  //       socket.io.opts.transports = ["polling", "websocket"];
  //     });
  //   }
  //   // return () => {};

  //   return () => {
  //     socket.off("session_refresh");
  //   };
  // }, [token, dispatch]);

  return <>{children}</>;
}

export default SocketProvider;
