import moment from "moment";
import React, { useEffect, useState } from "react";

const Tennis = ({ eventDetials, feedData }) => {
  const [liveDataFeed, setliveDataFeed] = useState(feedData);
  const [eventData, setEventData] = useState(eventDetials);

  useEffect(() => {
    setliveDataFeed(eventDetials);
  }, [eventDetials]);

  return (
    <>
      <div className="scorecard-main-container scorecardTennis">
        <div className="scorecard-row ">
          <div className="team-content">
            {(liveDataFeed?.feedData?.score?.event_final_result === "-" && (
              <p className="score"> - </p>
            )) ||
              (liveDataFeed?.feedData?.score?.event_final_result !== "-" && (
                <p className="score">
                  {
                    liveDataFeed?.feedData?.score?.event_final_result.split(
                      " - "
                    )[0]
                  }
                </p>
              ))}
            <div className="team_name">
              {liveDataFeed?.feedData?.score?.event_first_player}{" "}
              {liveDataFeed?.feedData?.score?.event_serve ===
                "First Player" && <sup>*</sup>}
            </div>
          </div>

          <div className="match_status">
            {(liveDataFeed?.feedData?.score?.event_status !== "" && (
              <span className="commantry">
                {liveDataFeed?.feedData?.score?.event_status}
              </span>
            )) ||
              (liveDataFeed?.feedData?.score?.event_status === "" && (
                <span className="commantry">
                  {moment(
                    liveDataFeed?.feedData?.score?.event_date.concat(
                      " ",
                      liveDataFeed?.feedData?.score?.event_time
                    )
                  ).format("ddd DD MMM, h:mm A")}
                </span>
              ))}
            <span className="day">
              <div className="set-servie_score">
                {(liveDataFeed?.feedData?.score?.event_status === "Set 1" &&
                  liveDataFeed?.feedData?.score?.scores.length === 1 && (
                    <span>
                      {liveDataFeed?.feedData?.score?.scores[0]?.score_first} -{" "}
                      {liveDataFeed?.feedData?.score?.scores[0]?.score_second}
                    </span>
                  )) ||
                  (liveDataFeed?.feedData?.score?.event_status === "Set 2" &&
                    liveDataFeed?.feedData?.score?.scores.length === 2 && (
                      <span>
                        {liveDataFeed?.feedData?.score?.scores[1]?.score_first}{" "}
                        -{" "}
                        {liveDataFeed?.feedData?.score?.scores[1]?.score_second}
                      </span>
                    )) ||
                  (liveDataFeed?.feedData?.score?.event_status === "Set 3" &&
                    liveDataFeed?.feedData?.score?.scores.length === 3 && (
                      <span>
                        {liveDataFeed?.feedData?.score?.scores[2]?.score_first}{" "}
                        -{" "}
                        {liveDataFeed?.feedData?.score?.scores[2]?.score_second}
                      </span>
                    )) ||
                  (liveDataFeed?.feedData?.score?.event_status === "" && (
                    <div className="">
                      <b>
                        <span>V</span> <span>S</span>
                      </b>
                    </div>
                  ))}{" "}
                {liveDataFeed.feedData?.score?.event_game_result !== "-" && (
                  <span className="set-score">
                    ({liveDataFeed.feedData?.score?.event_game_result})
                  </span>
                )}
                {(liveDataFeed.feedData?.score?.event_winner ===
                  "Second Player" && (
                  <span className="target">
                    {liveDataFeed?.feedData?.score?.event_second_player} won the
                    match by {liveDataFeed?.feedData?.score?.event_final_result}
                  </span>
                )) ||
                  (liveDataFeed.feedData?.score?.event_winner ===
                    "First Player" && (
                    <span className="target">
                      {liveDataFeed?.feedData?.score?.event_first_player} won
                      the match by{" "}
                      {liveDataFeed?.feedData?.score?.event_final_result}
                    </span>
                  ))}
              </div>
            </span>
          </div>

          <div className="team-content">
            {(liveDataFeed?.feedData?.score?.event_final_result === "-" && (
              <p className="score"> - </p>
            )) ||
              (liveDataFeed?.feedData?.score?.event_final_result !== "-" && (
                <p className="score">
                  {
                    liveDataFeed?.feedData?.score?.event_final_result.split(
                      " - "
                    )[1]
                  }
                </p>
              ))}
            <div className="team_name">
              {liveDataFeed?.feedData?.score?.event_second_player}{" "}
              {liveDataFeed?.feedData?.score?.event_serve ===
                "Second Player" && <sup>*</sup>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tennis;
