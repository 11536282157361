import io from "socket.io-client";

const socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
  transports: ["websocket", "polling"],
  auth: {
    token: localStorage.getItem("token"),
  },
});

export const socketFunc = (token) =>
  io.connect(process.env.REACT_APP_SOCKET_URL, {
    transports: ["websocket", "polling"],
    auth: {
      token: localStorage.getItem("token"),
    },
  });

export default socket;
