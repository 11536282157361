import React, { memo, useEffect, useState } from "react";
import Teamsection from "./children/eventteamsection";
import Eventstatussection from "./children/eventstatussection";
import { useDispatch, useSelector } from "react-redux";

const Cricket = ({ eventDetials, feedData }) => {
  const { sportEventData, sportLiveEventData } = useSelector(
    (state) => state.cricket
  );

  return (
    <>
      <div className="scorecard-main-container scorecardCricket">
        <div className="scorecard-row">
          <Teamsection
            teamName={{
              name: sportEventData.eventShortTeamA,
              type: "homeRunner",
            }}
            teamscore={sportLiveEventData.homeRunner}
          />

          <Eventstatussection
            feedStatus={sportLiveEventData}
            eventData={sportEventData}
          />

          <Teamsection
            teamName={{
              name: sportEventData.eventShortTeamB,
              type: "awayRunner",
            }}
            teamscore={sportLiveEventData.awayRunner}
          />
        </div>
      </div>
    </>
  );
};

export default memo(
  Cricket,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
);
